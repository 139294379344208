import React from 'react'
import {CCol,CRow} from '@coreui/react'
import AboutImageB from "../image/AboutB.png"
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaPinterest
  } from "react-icons/fa";

const Footer=()=>

{
    return(
        <>
        <div className=" p-4"  style={  {backgroundImage: `url(${AboutImageB})`,color:"white",paddingTop:"80px",paddingBottom:"50px"}}>
   
   <CRow>
        <CCol lg="6" sm="12" md="6" xl >
               <div style={{paddingBottom:"30px",paddingleft:"5px",textAlign:"center"}}>
                   <h3 style={{textAlign:"center"}}>REACH US</h3>
                   <h3>contact@rcndc.com</h3>
                   <h3>+251925002580/+251943141717</h3>
                   <h3>p.o.box 230/1070</h3>
                   <h3>Addis Ababa,Ethiopia</h3>
               </div>

        </CCol>

        <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
            
            
                     
        <div className="social-media"style={{textAlign:"center"}}>
          <h4>Social Media</h4>
          <a target="_blank" href="" style={{color:"white"}}>
              <FaInstagram className="space" size={30}/>
          </a>
          <a target="_blank" href="https://www.facebook.com/RCNDC-108409531316621" style={{color:"white"}}>
              <FaFacebookF className="space" size={30}/>
          </a>
          <a target="_blank" href="" style={{color:"white"}}>
          <FaTwitter className="space" size={30}/> 
              </a>     
              
         </div>




        </CCol>
  
       
    </CRow>  
      <h4 style={{paddingBottom:"50px",textAlign:"center"}}>2020 RCNDC. All rights reserved.</h4>
  </div>
  
        </>
    )
}

export default Footer;




     
