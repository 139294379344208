import React from 'react'
import {CCol,CRow} from '@coreui/react'
import AboutImageB from "../image/AboutB.png"

const Contact=()=>

{
  
    return(
      <>

<div className="p-4" style={{backgroundImage: `url(${AboutImageB})`,color:"white"}}>              
        <CRow>
             <CCol lg="6" sm="12" md="6" xl >
                <div >
                    <h1 style={{color:"white",textAlign:"center",paddingBottom:"10px"}}>CONTACT US</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                <button style={{textAlign:"center"}}>Read more</button>  
             </CCol>
        
           <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0 pt-5">
               <div style={{marginRight:"50px",marginLeft:"20px"}}>
               <input type="text" class="form-control " aria-describedby="emailHelp" placeholder="Your Name" style={{marginTop:"30px"}}></input> 
               <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your Subject" style={{marginTop:"30px"}}></input>   
             <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Your Message" style={{marginTop:"30px"}}></textarea>
             <button type="submit">Submit</button>
               </div>
            
          
             </CCol>
      
         
       
            
         </CRow>

         


 </div>
 

 </>
        

    )
}

export default Contact;