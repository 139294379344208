import React from 'react';
import {CCol,CRow} from '@coreui/react'
import {Image} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import logo from '../image/Logo.png'; 
import HomeBack from '../image/homeBACK.png';
import HomeImage from '../image/homeIMGC.png';
import RCNDCW from '../image/RCNDC-logo.png'
const Home=()=>

{
    return(
        <>

      
       
<div className=" p-4">
          
        
       
                <div className="container" >              
                   <CRow>
                    <CCol lg="5" sm="12" md="6" style={{marginTop:"60px"}}>
                        <div >
                           <h1>GROW YOUR BUSINESS</h1>
                        </div>   
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                        </p>  
                    </CCol>

                        <CCol lg="7" sm="10" md="6">
                        <Image src={HomeImage} fluid style={{paddingTop:"20px"}}/>
                    
                 
                    </CCol>
              
                   
                </CRow>

           </div>
        </div>
       
      
               
        </>
    )
}

export default Home;