import React, { Component,useRef,useEffect } from "react";
import { BrowserRouter as Router, HashRouter, Route, Switch } from 'react-router-dom';
import {CCol,CRow} from '@coreui/react'
import {Image,Container,Navbar} from 'react-bootstrap'
import RCNDCW from '../image/RCNDC-logo.png'
import Home from '../components/Home';
import About from '../components/About';
import Contact from '../components/Contacts';
import Service from '../components/Service';
import ERP from '../components/Service/ERP';
import NetworkInstallation from '../components/Service/Network_installation';
import SoftwareDevelopment from '../components/Service/SoftwareDevelopment';
import ItAssistance from '../components/Service/It_Assistance_Consultancy';
import Footer from '../components/Footer'
// import Navbar from '../components/Navbar'
import ScrollToTop from "../components/scrolltotop";


const Routes=()=>
 {

  const homeSection = React.createRef();
  const aboutSection = React.createRef();
  const serviceSection = React.createRef();
  const contactSection = React.createRef();

 

    const scrollDownHome=()=>
    {
      window.scrollTo({
        top:homeSection.current.offsetTop
      })
    }

    const scrollDownAbout=()=>
    {
      window.scrollTo({
        top:aboutSection.current.offsetTop
      })
    }

    const scrollDownService=()=>
    {
      window.scrollTo({
        top:serviceSection.current.offsetTop
      })
    }

    const scrollDownContact=()=>
    {
      window.scrollTo({
        top:contactSection.current.offsetTop
      })
    }
    return (
      <>

<Navbar>
  <Container>
    <Navbar.Brand href="#home">
                
        <Image src={RCNDCW} fluid style={{width:"100px"}}/>
    </Navbar.Brand>
    <Navbar.Toggle />
    <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
      <ul>
                    <li onClick={scrollDownAbout}>HOME</li>
                    <li onClick={scrollDownAbout}>ABOUT</li>
                    <li onClick={scrollDownService}>SERVICE</li>
                    <li onClick={scrollDownContact}>CONTACT</li>
                </ul>
      </Navbar.Text>
    </Navbar.Collapse>
  </Container>
</Navbar>


            
           <div className="APP2">
        
        <ScrollToTop/>
        <div ref={homeSection}>
        <Home/>
        </div>
        <div ref={serviceSection}>
        <Service/>
        </div>
      
        <div ref={aboutSection}>
          <About/>
        </div>
        
        <div ref={contactSection}>
        <Contact/>
        </div> 
        <Footer/> 
    
      </div>
        
       
      
        
         
        
         
       
      </>
    );
  }
  export default Routes;

