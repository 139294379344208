import React,{useEffect,useState} from 'react'
import {CCol,CRow} from '@coreui/react'
import {Card,Button,Image,Carousel} from 'react-bootstrap'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import ERPC from '../components/Service/ERP';
import NetworkInstallationC from '../components/Service/Network_installation';
import SoftwareDevelopmentC from '../components/Service/SoftwareDevelopment';
import ItAssistanceC from '../components/Service/It_Assistance_Consultancy';
import SystemDevelopment from '../components/Service/SystemDevelopment'
import ERP from '../image/erp.png';
import ITASSIST from '../image/ITassist.png';
import NETWORK from '../image/network.png';
import SOFTWARE from '../image/software.png';
import SYSTEMDEVELOP from '../image/SystemDevelopment.png';
import HomeBack from '../image/homeBACK.png';
import ServiceB from '../image/serviceB.png';
const Service=()=>
{ 

    const [screenWidth,setScreenWidth]=useState("")

    useEffect(() => {
     setScreenWidth(window.innerWidth)
     console.log(window.innerWidth)
     
   })


    return(

         <>

     <div className="">

     <div className="row">
                { 
                    (screenWidth > 980)
                    ? <div>  
                        <div style={{backgroundImage: `url(${HomeBack})`}}>
                                    <div className="ourservice" style={{paddingBottom:"30px",paddingTop:"30px"}}>
                                       <h1>OUR SERVICES</h1>
                                    </div>
                                    <div>
                                        <ERPC navbar={"false"}/>
                                        <NetworkInstallationC navbar={"false"}/>
                                        <SoftwareDevelopmentC navbar={"false"}/>
                                        <ItAssistanceC navbar={"false"}/>
                                        <SystemDevelopment navbar={"false"}/>
                                    </div>
                                </div> 
                                 </div> 
                    : 
                    <div> 
                           <h1>OUR SERVICES</h1>
                        <Carousel style={{textAlign:"center"}}>
                            <Carousel.Item>
                            <Link to="/service/erp">
                            <Image
                                className=""
                                src={ERP}
                                alt="First slide"
                                fluid
                                />
                            </Link>

                         
                            </Carousel.Item>

                            <Carousel.Item>
                                <Link to="/service/assistence">
                                    <Image
                                        className=""
                                        src={ITASSIST}
                                        alt="First slide"
                                        fluid
                                        />
                                </Link>

                                </Carousel.Item>
                                <Carousel.Item>
                                <Link to="/service/network">
                                    <Image
                                        className=""
                                        src={NETWORK}
                                        alt="First slide"
                                        fluid
                                        />
                                </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                <Link to="/service/software">
                                    <Image
                                        className=""
                                        src={SOFTWARE}
                                        alt="First slide"
                                        fluid
                                        />
                                </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                <Link to="/service/systemdecelopment">
                                    <Image
                                        className=""
                                        src={SYSTEMDEVELOP}
                                        alt="First slide"
                                        fluid
                                        />
                                </Link>

                            
                                </Carousel.Item>
                                </Carousel> 



                         </div> 
                }
                </div>                             
                     
     </div>
                                            
                             
        
              
         </>
    )
}



export default Service;


