import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router,Link } from 'react-router-dom';
import Navbar from '../../Navbar'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import ITASSISTANCE from '../../../image/ITASSISTANCE.png'
import ERPI from '../../../image/erp.png'
import HomeBack from '../../../image/homeBACK.png';
import RCNDCW from '../../../image/RCNDC-logo.png';
import Footer from '../../Footer';
const ERP=({navbar})=>

{
    const [Showavbar,setNavbar]=useState('')
    useEffect(() => {

        setNavbar(navbar)
    }    
    )

    
    
    return(
        <>

     
 {(Showavbar)? <div>
   
         <div className=" child conteiner-contact" style={{marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>ERP</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

              
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
               <Image src={ITASSISTANCE} fluid style={{width:"900px"}}/>
             </CCol>
       
            
         </CRow>

         


 </div>
            </div> :
            <div>
           

             
                      <div className=" child  conteiner-contact" style={{backgroundImage: `url(${HomeBack})`}}>             
                <Navbar/> 

             
                <div>
           
            </div>
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div>
                    <h1 style={{textAlign:"center",paddingBottom:"20px"}}>ERP</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

            
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
               <Image src={ITASSISTANCE} fluid style={{width:"900px"}}/>
             </CCol>
       
            
         </CRow>

         


 </div>

                <Footer/>
          
       
            </div> }
        
 

        </>
    )
}

export default ERP;