import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Navbar from '../Navbar'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import NetworkD from '../../image/NetworkDC.png'
import ServiceB from '../../image/serviceB.png';

const NetworkInstallation=({navbar})=>

{
    const [Showavbar,setNavbar]=useState('')
    useEffect(() => {

        setNavbar(navbar)
    }    
    )
    return(
        <>

{(Showavbar)? <div>
    <div className="conteiner-contact" style={{marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>IT ASSISTANCE</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                <button>
                    <Link to="/service/assistence" style={{color:"rgba(4, 13, 116, 0.712)"}}>
                    Read more
                    </Link>
                     </button>    
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
             <Image src={NetworkD} fluid style={{width:"600px"}}/>
             </CCol>
       
            
         </CRow>

         


 </div>
                
     </div> : 
     <div>
         <Navbar/>
      <div className="conteiner-contact" style={{backgroundImage: `url(${ServiceB})`,marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px",backgroundPosition:"center"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>IT ASSISTANCE</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                <button>
                    <Link to="/service/assistence" style={{color:"rgba(4, 13, 116, 0.712)"}}>
                    Read more
                    </Link>
                     </button>    
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
             <Image src={NetworkD} fluid style={{width:"900px"}}/>
             </CCol>
       
            
         </CRow>

         


 </div>
   </div>
}
 

        </>
    )
}


export default NetworkInstallation;