import React from 'react';
import {CCol,CRow} from '@coreui/react'
import RCNDCW from '../image/RCNDC-logo.png'
import {Image,Container,Navbar} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import logo from '../image/Logo.png'; 

const NavBar=()=>

{
    return(
        <>
       
     <div style={{paddingBottom:"50px"}}>

     <Navbar>
  <Container>
    <Navbar.Brand href="#home">
    <Image src={RCNDCW} fluid style={{width:"100px"}}/>
    </Navbar.Brand>
    <Navbar.Toggle />
    <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
      <ul>
                  <li><a href="/" style={{textDecoration:"none",color:"rgba(0,70,130)",fontFamily:"fantasy"}}>HOME</a></li>
                  <li><a href="/" style={{textDecoration:"none",color:"rgba(0,70,130)"}}>ABOUT</a></li>
                  <li><a href="/" style={{textDecoration:"none",color:"rgba(0,70,130)"}}>SERVICE</a></li>
                  <li><a href="/" style={{textDecoration:"none",color:"rgba(0,70,130)"}}>CONTACT</a></li>
              </ul>
      </Navbar.Text>
    </Navbar.Collapse>
  </Container>
</Navbar>
  
     

 

     </div>
         
        
      
               
        </>
    )
}

export default NavBar;