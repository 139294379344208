import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Navbar from '../Navbar'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import ERPI from '../../image/erp.png'
import ServiceB from '../../image/serviceB.png';

const SystemDevelopment=({navbar})=>

{
    const [Showavbar,setNavbar]=useState('')
    useEffect(() => {

        setNavbar(navbar)
    }    
    )
    return(
        <>

     
{(Showavbar)? 
            <div> 
                         <div className="conteiner-contact" style={{marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>System Development</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                <button>
                    <Link to="/service/systemdevelopment" style={{color:"rgba(4, 13, 116, 0.712)"}}>
                    Read more
                    </Link>
                     </button>    
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
               <Image src={ERPI} fluid style={{width:"300px"}}/>
             </CCol>
       
            
         </CRow>

         


    </div>
            </div>:
            <div>
                 <Navbar/>
                     <div className="conteiner-contact" style={{backgroundImage: `url(${ServiceB})`,marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px",backgroundPosition:"center"}}>              
                <CRow>
                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                        <div >
                            <h1>System Development</h1>
                        </div>   
                        <div>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                        </div>

                        <button>
                            <Link to="/service/software" style={{color:"rgba(4, 13, 116, 0.712)"}}>
                            Read more
                            </Link>
                            </button>    
                    </CCol>

                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                    <Image src={ERPI} fluid style={{width:"900px"}}/>
                    </CCol>
            
                    
                </CRow>

         


              </div>
            </div>}
         
 </>
    )
}

export default SystemDevelopment;