import React from 'react'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import AboutImageB from "../image/AboutB.png"
import AboutImage from "../image/aboutIMAGEC.png"

const About=()=>

{
    return(
        <>

   <div className="" style={{backgroundImage: `url(${AboutImageB})`}}>              
        <CRow>
             <CCol lg="6" sm="12" md="6" xl >
                <div >
                    <h1 style={{color:"white"}}>ABOUT US</h1>
                </div>   
                <div style={{color:"white"}}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                <button style={{marginBottom:"50px"}}>Read more</button>  
             </CCol>

             <CCol lg="6" sm="12" md="6" xl style={{paddingBottom:"20px"}}>
             
             <Image src={AboutImage} fluid/>
             </CCol>
       
            
         </CRow>

         


 </div>
 

        </>
    )
}

export default About;