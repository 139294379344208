import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, HashRouter, Route, Switch } from 'react-router-dom';

import ERP from './components/Service/ServiceDetail/ERP';
import Consultance from './components/Service/ServiceDetail/It_Assistance_Consultancy';
import NetworkInstallation from './components/Service/ServiceDetail/Network_installation';
import SoftwareDevelopment from './components/Service/ServiceDetail/SoftwareDevelopment';
import ItAssistance from './components/Service/ServiceDetail/It_Assistance_Consultancy';
import SystemDevelopment from './components/Service/ServiceDetail/SystemDevelopment';

import Service from './components/Service'

import Routes from './Routes/Route'
import Home from './components/Home'

function App() {
  return (
    <div className="App">

          <Router>
                <Route exact path="/" component={Routes}></Route>
                <div className="APP2">
                    <Route exact  path="/service/erp" component={ERP}></Route>
                    <Route exact path="/service/assistence" component={ItAssistance}></Route> 
                    <Route exact path="/service/network" component={NetworkInstallation}></Route>
                    <Route exact path="/service/software" component={SoftwareDevelopment}></Route>
                    <Route exact path="/service/systemdevelopment" component={SystemDevelopment}></Route>

                </div>
                
              
          </Router>
        
            
    </div>
  );
}

export default App;
