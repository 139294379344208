import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Navbar from '../../Navbar'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import ERPI from '../../../image/erp.png'
import HomeBack from '../../../image/homeBACK.png';
import ITASSISTANCE from '../../../image/ITASSISTANCE.png'
import Footer from '../../Footer';

const SystemDevelopment=({navbar})=>

{
    const [Showavbar,setNavbar]=useState('')
    useEffect(() => {

        setNavbar(navbar)
    }    
    )
    return(
        <>

     
{(Showavbar)? 
            <div> 
                         <div className="conteiner-contact" style={{marginTop:"100px",marginLeft:"20px",marginBottom:"50px"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>System Development</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
               <Image src={ERPI} fluid style={{width:"900px"}}/>
             </CCol>
       
            
         </CRow>

         


    </div>
            </div>:
            <div>
               
               
               <div className=" child  conteiner-contact" style={{backgroundImage: `url(${HomeBack})`}}>             
                <Navbar/>       
                <CRow>
                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                        <div style={{textAlign:"center",paddingBottom:"20px"}}>
                            <h1>System Development</h1>
                        </div>   
                        <div>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                        </div>
   
                    </CCol>

                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                    <Image src={ITASSISTANCE} fluid style={{width:"900px"}}/>
                    </CCol>
            
                    
                </CRow>

         


              </div>
              <Footer/>
            </div>}
         
 </>
    )
}

export default SystemDevelopment;