import React,{useEffect,useState} from 'react'
import {BrowserRouter as Router,Link} from 'react-router-dom';
import Navbar from '../../Navbar'
import {Image} from 'react-bootstrap'
import {CCol,CRow} from '@coreui/react'
import Philantrophy from '../../../image/philantrophy.png'
import RcndcW from '../../../image/RCNDCW.jpg'
import Adimera from '../../../image/adimera.png'
import HomeBack from '../../../image/homeBACK.png';
import Hagere from '../../../image/yehagere.png';
import Kush from '../../../image/Kush.png';
import SoftwareD from '../../../image/software.png';
import Footer from '../../Footer';
const SoftwareDevelopment=({navbar})=>

{
    const [Showavbar,setNavbar]=useState('')
    useEffect(() => {

        setNavbar(navbar)
    }    
    )
    return(
        <>

{(Showavbar)? <div> <div className="conteiner-contact" style={{marginTop:"100px",marginLeft:"20px",paddingTop:"100px",marginBottom:"50px"}}>              
           <CRow>
             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                <div >
                    <h1>SOFTWARE DEVELOPMENT</h1>
                </div>   
                <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                </div>

                  
             </CCol>

             <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
             <Image src={SoftwareD} fluid/>

             </CCol>
       
            
         </CRow>

         


 </div> </div>:
 
 
        <div className=" child  conteiner-contact" style={{backgroundImage: `url(${HomeBack})`}}>             
                <Navbar/> 
     <div >

           <CRow>
                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                            <div>
                                <h1>SOFTWARE DEVELOPMENT</h1>
                            </div>   
                            <div>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus cupiditate possimus sapiente, eaque beatae nemo? Provident temporibus facere iure tenetur ea corrupti odit quam vel, rem reprehenderit inventore omnis porro!
                            </div>

                    </CCol>
                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                            <Image src={SoftwareD} fluid/>
                    </CCol>
             </CRow>

             <CRow>
             <div style={{paddingTop:"100px"}}>
                    <h1 style={{textAlign:"center"}}>Our Products</h1>
             </div>   
                    <CCol lg="6" sm="12" md="6" xl className="mb-3 mb-xl-0">
                         
                      

                    </CCol>
               
             </CRow>


             <div style={{paddingTop:"50px"}}>
             <CRow>
                <CCol lg="3" sm="6" md="3" xl className="mb-3 mb-xl-0" >
                    <a target="_blank" href="https://www.yehagere.com">

                        <Image src={Hagere} fluid style={{width:"250px",backgroundColor:"rgba(212,165,152)"}} roundedCircle />
                    </a>

                </CCol>
                <CCol lg="3" sm="6" md="3" xl className="mb-3 mb-xl-0" >
                    <a target="_blank" href="https://www.adimera.com">
                    
                    
                    <Image src={Adimera} fluid style={{width:"250px"}}  />

                    </a>
                
                </CCol>
                <CCol lg="3" sm="6" md="3" xl className="mb-3 mb-xl-0" >
                    <a target="_blank" href="https://www.philanthrophyethiopia.com">
                    <Image src={Philantrophy} fluid style={{width:"250px"}}  />
                    </a>  
             </CCol>
             <CCol lg="3" sm="6" md="3" xl className="mb-3 mb-xl-0" >
                    <a target="_blank" href="https://www.kushlivestock.com">
                    
                        <Image src={Kush} fluid style={{width:"250px"}}  />
                    </a>
                        
             </CCol>
         </CRow>

         
             </div>
             

         


 </div>

 </div>}
        
 <Footer/>

        </>
    )
}


export default SoftwareDevelopment;